import Helper from '@/services/helper';

export default {
    async getLandingContent() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/promotion/get-landing-content');
        
        return result;
    },
    async getPromotionList(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/promotion/get-list', param);
        
        return result;
    },
    async getPromotionDetail(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/promotion/get-detail', param);
        
        return result;
    },
    async getPromotionRelated(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/promotion/get-related', param);
        
        return result;
    }
}